<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="9" sm="9" md="9">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12" lg="8" sm="8" md="8"
                      ><v-text-field
                        outlined
                        v-model="params.search"
                        prepend-inner-icon="mdi mdi-magnify"
                        label="ค้นหา"
                        dense
                        hide-details
                      ></v-text-field
                    ></v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn color="purple" block dark @click="search">
                        <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                      >
                    </v-col>
                    <v-col cols="6" lg="auto" md="auto" sm="auto">
                      <v-btn
                        elevation="2"
                        color="purple"
                        block
                        outlined
                        class="mx-1"
                        @click="clear"
                      >
                        <v-icon>mdi-sync</v-icon>
                        เคลียร์
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" lg="auto" sm="auto" md="auto">
                <v-btn
                  block
                  color="primary"
                  class="font-weight-bold"
                  @click="drawer = true"
                >
                  <v-icon>mdi mdi-plus</v-icon>
                  สร้าง Admin</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <DataTable
              :headers="headers"
              :meta="meta"
              :desserts="desserts"
              @deleteItem="deleteItem"
              @editItem="editItem"
              @selectPage="selectPage"
              @setCurrentPage="setCurrentPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      right
      top
      :width="$vuetify.breakpoint.mobile ? '100%' : '50%'"
      class="bg-gradient-purple"
    >
      <v-row no-gutters justify="end">
        <v-col cols="12">
          <v-toolbar
            flat
            dark
            dense
            color="transparent"
            width="100%"
            max-height="50px"
          >
            <v-btn icon dark @click="drawer = false" class="focus:outline-none">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <h1 class="text-white text-2xl font-bold ma-0 pa-0 text-center">
            <span v-if="!editID">สร้าง Admin</span>
            <span v-else>แก้ไข Admin</span>
          </h1>

          <v-row no-gutters justify="center" class="pa-4">
            <v-col cols="12" lg="10">
              <v-form
                class="ma-0 pa-0"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-row no-gutters>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Username
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formAdmin.username"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0" v-if="!editID">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Password
                    </p>
                    <v-text-field
                      dark
                      type="password"
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formAdmin.password"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Name</p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formAdmin.name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Nickname
                    </p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formAdmin.nickname"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">Tel</p>
                    <v-text-field
                      dark
                      :rules="nameRules"
                      required
                      v-bind="textFieldProp"
                      v-model="formAdmin.tel"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      BirthDate
                    </p>

                    <v-dialog
                      ref="birthDateDialog"
                      v-model="birthDateDialog"
                      :return-value.sync="formAdmin.birth_date"
                      persistent
                      max-width="350"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="formAdmin.birth_date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dark
                          :rules="nameRules"
                          required
                          v-bind="{ ...textFieldProp, attrs }"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="formAdmin.birth_date"
                        scrollable
                        locale="th"
                        width="100%"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="birthDateDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.birthDateDialog.save(formAdmin.birth_date)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Picture
                    </p>
                    <v-text-field
                      dark
                      v-bind="textFieldProp"
                      v-model="formAdmin.picture"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Base salary
                    </p>
                    <v-text-field
                      dark
                      type="number"
                      v-bind="textFieldProp"
                      v-model="formAdmin.base_salary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2 py-0">
                    <p class="ma-0 pa-0 text-white font-light text-sm">
                      Balance salary
                    </p>
                    <v-text-field
                      dark
                      type="number"
                      v-bind="textFieldProp"
                      v-model="formAdmin.balance_salary"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      dark
                      v-model="rolesData.dashboard.available"
                      @click="
                        validateRole('dashboard', rolesData.dashboard.available)
                      "
                    >
                      <template v-slot:label>
                        <span class="font-bold text-white text-lg"
                          >เมนู Dashboard</span
                        >
                      </template>
                    </v-checkbox>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                        v-for="(item, index) in roles.dashboard.menu"
                        :key="index"
                      >
                        <v-checkbox
                          dark
                          :label="item"
                          :value="item"
                          :disabled="!rolesData.dashboard.available"
                          v-model="rolesData.dashboard.menu"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="bg-white"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox dark v-model="rolesData.admin.available">
                      <template v-slot:label>
                        <span class="font-bold text-white text-lg"
                          >เมนู Admin</span
                        >
                      </template>
                    </v-checkbox>

                    <v-divider class="bg-white"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      dark
                      v-model="rolesData.member.available"
                      @click="
                        validateRole('member', rolesData.member.available)
                      "
                    >
                      <template v-slot:label>
                        <span class="font-bold text-white text-lg"
                          >เมนู Member</span
                        >
                      </template>
                    </v-checkbox>

                   
                    <v-row>
                    
                      <v-col
                        cols="6"
                        class="py-0"
                        v-for="(item, index) in roles.member.menu"
                        :key="index"
                      >
                        <v-checkbox
                          dark
                          :label="item"
                          :value="item"
                          :disabled="!rolesData.member.available"
                          v-model="rolesData.member.menu"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                     <v-checkbox
                      dark
                      v-model="rolesData.member.credit"
                      @click="
                        validateRole('member', rolesData.member.credit)
                      "
                    >
                      <template v-slot:label>
                        <span class="font-bold text-red-600 text-lg"
                          >อัพเดทเครดิต</span
                        >
                      </template>
                    </v-checkbox>
                    <v-divider class="bg-white"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      dark
                      v-model="rolesData.deposit.available"
                      @click="
                        validateRole('deposit', rolesData.deposit.available)
                      "
                    >
                      <template v-slot:label>
                        <span class="font-bold text-white text-lg"
                          >เมนู Deposit</span
                        >
                      </template>
                    </v-checkbox>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                        v-for="(item, index) in roles.deposit.menu"
                        :key="index"
                      >
                        <v-checkbox
                          dark
                          :label="item"
                          :value="item"
                          :disabled="!rolesData.deposit.available"
                          v-model="rolesData.deposit.menu"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="bg-white"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      dark
                      v-model="rolesData.withdraw.available"
                      label="Withdraw"
                      @click="
                        validateRole('withdraw', rolesData.withdraw.available)
                      "
                    >
                      <template v-slot:label>
                        <span class="font-bold text-white text-lg"
                          >เมนู Withdraw</span
                        >
                      </template>
                    </v-checkbox>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                        v-for="(item, index) in roles.withdraw.menu"
                        :key="index"
                      >
                        <v-checkbox
                          dark
                          :label="item"
                          :value="item"
                          :disabled="!rolesData.withdraw.available"
                          v-model="rolesData.withdraw.menu"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="bg-white"></v-divider>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      dark
                      v-model="rolesData.transfer.available"
                      label="Transfer"
                      @click="
                        validateRole('transfer', rolesData.transfer.available)
                      "
                    >
                      <template v-slot:label>
                        <span class="font-bold text-white text-lg"
                          >เมนู Transfer</span
                        >
                      </template>
                    </v-checkbox>

                    <v-row>
                      <v-col
                        cols="6"
                        class="py-0"
                        v-for="(item, index) in roles.transfer.menu"
                        :key="index"
                      >
                        <v-checkbox
                          dark
                          :label="item"
                          :value="item"
                          :disabled="!rolesData.transfer.available"
                          v-model="rolesData.transfer.menu"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider class="bg-white"></v-divider>
                  </v-col>
                </v-row>
                <!-- Action -->
                <v-row justify="start" v-if="drawer" >
                  <v-col cols="12" lg="6" sm="6" md="6">
                    <v-btn
                      block
                      large
                      dark
                      outlined
                      depressed
                      @click="confirmCreateDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">บันทึก</span>
                      </template>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="6"
                    sm="6"
                    md="6"
                    class="px-4"
                    v-if="editID"
                  >
                    <v-btn
                      block
                      large
                      dark
                      depressed
                      color="primary"
                      @click="forgotPasswordDialog = true"
                      class="focus:outline-none border-2 rounded-lg"
                    >
                      <template v-slot:default>
                        <span class="text-bold text-lg">แก้ไขรหัสผ่าน</span>
                      </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog v-model="confirmCreateDialog" persistent max-width="320">
        <v-card tile flat>
          <v-card-text>
            <h1 class="text-xl font-bold mt-4 mb-2">
              <span v-if="!editID">สร้าง Admin</span>
              <span v-else>แก้ไข Admin</span>
            </h1>
            <p class="text-sm font-light">
              <span v-if="!editID">
                คุณต้องการที่จะสร้างรายการนี้ หรือไม่?</span
              >
              <span v-else> คุณต้องการที่จะแก้ไขรายการนี้ หรือไม่?</span>
            </p>
          </v-card-text>
          <v-row no-gutters justify="end">
            <v-col cols="6">
              <v-btn
                color="error"
                depressed
                text
                tile
                @click="confirmCreateDialog = false"
              >
                ยกเลิก
              </v-btn>

              <v-btn color="success" text depressed tile @click="onSubmit">
                ยืนยัน
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>

      <v-dialog v-model="forgotPasswordDialog" persistent max-width="500">
        <v-card tile flat>
          <v-form class="ma-0 pa-0" ref="forgotPasswordForm" lazy-validation>
            <v-card-text>
              <h1 class="text-xl font-bold mt-4 mb-2">
                <span>แก้ไขรหัสผ่าน</span>
              </h1>
              <v-row>
                <v-col cols="12">
                  <p class="ma-0 pa-0 text-white font-light text-sm">
                    รหัสผ่านใหม่
                  </p>
                  <v-text-field
                    type="password"
                    placeholder="รหัสผ่านใหม่"
                    :rules="nameRules"
                    outlined
                    v-model="newPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-row no-gutters justify="end">
              <v-col cols="6" class="text-right pa-4">
                <v-btn
                  color="error"
                  depressed
                  text
                  tile
                  @click="forgotPasswordDialog = false"
                >
                  ยกเลิก
                </v-btn>

                <v-btn
                  color="success"
                  text
                  depressed
                  tile
                  @click="forgotPassword"
                >
                  ยืนยัน
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable";
import UserService from "./../../services/user.service.js";
import { roles, roleMenu } from "./../../utils/role";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "center",
          sortable: true,
          value: "index"
        },
        { text: "Username", value: "username" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions" }
      ],
      meta: {},
      desserts: [],
      editID: null,
      drawer: false,
      valid: false,
      confirmCreateDialog: false,
      params: {
        limit: 10,
        page: 1,
        search: null
      },
      birthDateDialog: false,
      textFieldProp: {
        color: "transparent",
        solo: true,
        flat: true,
        outlined: false,
        dense: false,
        backgroundColor: "#5505AF"
      },
      nameRules: [(v) => !!v || "*กรุณากรอกข้อมูล"],
      formAdmin: {
        username: null,
        password: null,
        name: null,
        nickname: null,
        tel: null,
        birth_date: new Date().toISOString().substr(0, 10),
        picture: null,
        base_salary: 0,
        balance_salary: 0,
        role: ""
      },
      roles,
      roleMenu,
      rolesData: {
        dashboard: {
          available: false,
          menu: []
        },
        admin: {
          available: false
        },
        member: {
          available: false,
          credit: false,
          menu: []
        },
        deposit: {
          available: false,
          menu: []
        },
        withdraw: {
          available: false,
          menu: []
        },
        transfer: {
          available: false,
          menu: []
        }
      },
      forgotPasswordDialog: false,
      newPassword: ""
    };
  },
  watch: {
    drawer(value) {
      if (!value) {
        this.clearForm();
      }
    },
     
  },
  filters: {
    dateFormat(value) {
      return new Date(value).toISOString().substr(0, 10);
    }
  },
  methods: {
    ...mapActions({
      alert: "notification/show"
    }),
    async findAll(options) {
      try {
        const response = await UserService.findAll(options);
        if (response.status === 200) {
          const results = [];

          response.data.items.map((item, index) => {
            results.push({
              ...item,
              index: index + 1,
              created_at: new Date(item.created_at).toISOString().substr(0, 10),
              updated_at: new Date(item.updated_at).toISOString().substr(0, 10),
              birth_date: new Date(item.birth_date).toISOString().substr(0, 10)
            });
          });
          this.desserts = results;
          this.meta = response.data.meta;
        }
      } catch (error) {
        new Promise.reject(error);
      }
    },
    validateRole(role, status) {
      if (!status) {
        this.rolesData[role].menu = [];
      }
    },
    async deleteItem(value) {
      if (value) {
        const { id } = value;

        const deleted = await UserService.delete(id);
        if (deleted.status === 200) {
          this.findAll(this.params);
          this.drawer = false;
          this.clearForm();
        }
      }
    },
    editItem(value) {
      if (value) {
        this.editID = value.id;
        this.formAdmin = this.lodash.cloneDeep(value); //cloneDeep : freeze data [disable two way data binding]
        this.rolesData = JSON.parse(value.role);

        this.drawer = true;
        this.$refs.form.resetValidation();
      }
    },
    selectPage(value) {
      if (value) {
        this.params.limit = value;
        this.findAll(this.params);
      }
    },
    setCurrentPage(value) {
      if (value) {
        this.params.page = value;
        this.findAll(this.params);
      }
    },
    search() {
      this.findAll(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findAll(this.params);
    },
    clearForm() {
      this.editID = null;
      this.formAdmin = this.$options.data.call(this).formAdmin;
      this.rolesData = this.$options.data.call(this).rolesData;

      this.$refs.form.resetValidation();
    },
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.formAdmin.base_salary = parseInt(this.formAdmin.base_salary);
        this.formAdmin.balance_salary = parseInt(this.formAdmin.balance_salary);
        this.formAdmin.role = JSON.stringify(this.rolesData);

        if (!this.editID) {
          const created = await UserService.create(this.formAdmin);

          if (created.status === 201) {
            this.findAll(this.params);
            this.drawer = false;
            this.clearForm();
          }
        } else {
          const updated = await UserService.update(this.editID, this.formAdmin);

          if (updated.status === 200) {
            this.findAll(this.params);
            this.drawer = false;
            this.clearForm();
          }
        }
      } else {
        this.confirmCreateDialog = false;
      }
    },
    async forgotPassword() {
      if (this.$refs.forgotPasswordForm.validate()) {
        const { data, status } = await UserService.forgotPassword(
          this.editID,
          this.newPassword
        );

        status === 200
          ? this.alert({ type: "success", text: "แก้ไขรหัสผ่านเรียบร้อย" })
          : this.alert({
              type: "error",
              text: "ไม่แก้ไขรหัสผ่านได้ กรุณาตรวจสอบข้อมูลอีกครั้ง"
            });
        this.newPassword = "";
        this.forgotPasswordDialog = false;
        this.drawer = false;
        return data;
      }
    }
  },

  mounted() {
    this.$nextTick(() => this.findAll(this.params));
  },
  components: {
    DataTable
  }
};
</script>
